import React from 'react'
import Form from '../components/form'
import { NavLink } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa'

function ContactsPage() {
  return (
    <div className='py-10 px-[30px] md:px-[20px]'>
      <div className='grid grid-cols-2 gap-10 items-center lg:grid-cols-1'>
        <div className='contacts_form form contacts_form div'>
          <Form
            title={'Контакты'}
            text={'Свяжитесь с нами и мы ответим на все вопросы по строительству, а также будем рады видеть Вас в нашем офисе.'}
          />
        </div>
        <div className='text-white grid gap-5 justify-center lg:text-center'>
          <div>
            <h5 className='text-xl'>Адрес:</h5>
            <NavLink className={'text-sm'}>
              г. Бишкек, ул. Льва Толстого 36К, БЦ Монолит
            </NavLink>
          </div>
          <div>
            <h5 className='text-xl'>Электронная почта:</h5>
            <NavLink target='_blank' to={'mailto:isakurulush@gmail.com'} className={'text-sm block'}>isakurulush@gmail.com</NavLink>
            <NavLink target='_blank' to={'mailto:support@isakurulush.kg'} className={'text-sm'}>stroy@isakurulush.kg</NavLink>
          </div>
          <div>
            <h5 className='text-xl'>Мы  в социальных сетях:</h5>
            <div className='flex gap-5 lg:justify-center'>
              <NavLink target='_blank' to={'https://wa.me/+996705223711'} className={'text-2xl'}><FaWhatsapp /></NavLink>
              <NavLink target='_blank' to={'https://t.me/+996705223711'} className={'text-2xl'}><FaTelegramPlane /></NavLink>
              <NavLink target='_blank' to={'https://www.instagram.com/isa.kurulush/'} className={'text-2xl'}><FaInstagram /></NavLink>
              <NavLink target='_blank' to={'https://www.facebook.com/isa.kurulush'} className={'text-2xl'}><FaFacebook /></NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactsPage