import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaWhatsapp, FaTelegramPlane, FaInstagram, FaFacebook } from "react-icons/fa";
import logo from '../../img/logo-isakurulush.jpg'

const handleScroll = (navigate) => {
    navigate('/', { state: { scrollToStages: true } });
};

function Footer() {

    const navigate = useNavigate()

    return (
        <div className='grid gap-10 bg-[#394e25] px-[30px] py-[40px] mt-10  md:px-[20px]'>
            <div className='flex justify-between gap-4 items-center sm:flex-col'>
                <div className='text-white max-w-[60px]'>
                    <NavLink to={'/'}>
                        <img className='w-full' src={logo} alt="ИСА СТРОЙ" />
                    </NavLink>
                </div>
                <nav className=''>
                    <ul className='text-white text-center flex gap-5 text-sm font-light sm:gap-2 md:flex-col'>
                        <li className=' hover:opacity-80'><NavLink to={'/about'}>О нас</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/projects'}>Мы строим</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/'} onClick={() => handleScroll(navigate)}>Этапы сотрудничества</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/gallery'}>Галерея</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/contacts'}>Контакты</NavLink></li>
                    </ul>
                    <ul className='text-white text-2xl flex gap-5 pt-3 sm:justify-center'>
                        <li><NavLink target='_blank' to={'https://wa.me/+996705223711'}><FaWhatsapp /></NavLink></li>
                        <li><NavLink target='_blank' to={'https://t.me/+996705223711'}><FaTelegramPlane /></NavLink></li>
                        <li><NavLink target='_blank' to={'https://www.instagram.com/isa.kurulush/'}><FaInstagram /></NavLink></li>
                        <li><NavLink target='_blank' to={'https://www.facebook.com/isa.kurulush'}><FaFacebook /></NavLink></li>
                    </ul>
                </nav>
            </div>
            <hr />
            <div className='text-white text-center flex gap-10 sm:justify-center md:flex-col md:gap-5'>
                <p>2024 Все права защищены.</p>
                <NavLink target='_blank' to={'https://krakendevs.com/'} className={'hover:opacity-80'}>Разработка сайта</NavLink>
            </div>
        </div>
    )
}

export default Footer