import { createSlice } from '@reduxjs/toolkit'
import { Api } from '../../api'

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        projects: []
    },
    reducers: {
        SetProjects: (state, action) => {
            state.projects = action.payload
        }
    }
})

export const { SetProjects } = projectsSlice.actions
export default projectsSlice.reducer

export const getProjects = () => {
    return async (dispatch) => {
        try {
            const response = await Api.get('projects/')
            dispatch(SetProjects(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}