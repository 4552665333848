import {configureStore} from '@reduxjs/toolkit'
import projectsSlice from './projects/projects'
import gallerySlice from './gallery/gallery'
import mainSlice from './main/main'

const store = configureStore({
    reducer: {
        projectsSlice,
        gallerySlice,
        mainSlice
    }
})

export default store