import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGallery } from '../redux/gallery/gallery';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function GalleryPage() {

    const dispatch = useDispatch()
    const gallery = useSelector(state => state.gallerySlice.gallery)
    console.log(gallery);

    useEffect(() => {
        dispatch(getGallery())
    }, [dispatch])

    return (
        <div className='py-10 px-[30px] md:px-[20px]'>
            <div className='flex flex-wrap gap-5 justify-center'>
                {
                    gallery ? gallery.map((item, index) => (
                        <div key={index} className='max-w-[300px]'>
                            {Fancybox.bind('[data-fancybox="gallery"]', { src: item.img })}
                            <img className='w-full rounded-[12px]'
                                src={item.img}
                                data-fancybox="gallery" alt="" />
                        </div>
                    )): 'loading...'}
            </div>
        </div>
    )
}

export default GalleryPage