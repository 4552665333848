import { createSlice } from '@reduxjs/toolkit'
import { Api } from '../../api'

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        main: []
    },
    reducers: {
        SetMain: (state, action) => {
            state.main = action.payload
        }
    }
})

export const { SetMain } = mainSlice.actions
export default mainSlice.reducer

export const getMain = () => {
    return async (dispatch) => {
        try {
            const response = await Api.get('main/')
            dispatch(SetMain(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}