import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/header";
import HomePage from "./pages/home-page";
import ProjectsPage from "./pages/projects-page";
import GalleryPage from "./pages/gallery-page";
import AboutPage from "./pages/about-page";
import Footer from "./components/footer/footer";
import Button from "./components/button";
import { IoIosArrowUp } from "react-icons/io";
import ContactsPage from "./pages/contacts-page";
import { useEffect } from "react";

function App() {

  const location = useLocation()

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(()=> {
    handleScrollUp()
  }, [location])

  return (
    <div className="App relative">
      <Header />
      <div className={'scroll_up rounded-full fixed bottom-10 right-10 z-10'}
        onClick={handleScrollUp}
      >
        <Button children={<IoIosArrowUp size={30} />} />
      </div>
      <Routes location={location}>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
