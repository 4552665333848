import React, { useEffect } from 'react'
import Card from './card'
import Button from './button'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FormModal from './form_modal';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from '@fancyapps/ui';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../redux/projects/projects';

function Projects({ handleShowModal, showModal }) {

    const dispatch = useDispatch()
    const projects = useSelector(state => state.projectsSlice.projects)
    console.log(projects);

    useEffect(() => {
        dispatch(getProjects())
    }, [dispatch])

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            {projects.length > 0 ? (
                <div className='mt-10 px-[30px] md:px-[20px]'>
                    <Card text={'Мы строим'} />
                    <h2 className='text-white text-4xl font-medium pt-5 md:text-3xl'>Качество, сроки и доступные цены превыше всего </h2>
                    <div className='flex flex-wrap justify-center gap-10 pt-8 lg:gap-5'>
                        {
                            projects.length <= 3 ? projects.slice(-3).map((item, index) => (
                                <div key={index} className='bg-[#353535] p-3 rounded-[20px] max-w-[300px]'>
                                    <div className='w-full'>
                                        <Slider {...settings}>
                                            {
                                                item.project_images.map((items, i) => (
                                                    <div key={i} className='rounded-[20px] max-h-[180px]'>
                                                        {Fancybox.bind('[data-fancybox="gallery"]', { src: items.img })}
                                                        <img className='h-full rounded-[20px]' data-fancybox="gallery" src={items.img} alt="" />
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                    <div className='grid gap-4 mt-5'>
                                        <h5 className='text-white font-medium text-2xl'>{item.title}</h5>
                                        <ul className='text-white text-sm font-light'>
                                            <li className='flex justify-between mb-2'>
                                                <p>Общая площадь</p>
                                                <span>{item.area} м<sup>2</sup></span>
                                            </li>
                                            <li className='flex justify-between'>
                                                <p>Материал</p>
                                                <span dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </li>
                                        </ul>
                                        <Button children={'Узнать подробнее'} onClick={handleShowModal} />
                                        {showModal && <FormModal />}
                                    </div>
                                </div>
                            )) : 'loading...'}
                    </div>
                </div>
            ) : ''}
        </>
    )
}

export default Projects