import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../redux/projects/projects';
import Slider from 'react-slick';
import Button from '../components/button';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from '@fancyapps/ui';

function ProjectsPage() {

    const dispatch = useDispatch()
    const projects = useSelector(state => state.projectsSlice.projects)
    console.log(projects);

    useEffect(() => {
        dispatch(getProjects())
    }, [dispatch])

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='flex flex-wrap gap-10 justify-center py-10 px-[30px] md:px-[20px]'>
            {
                projects ? projects.map((item, index) => (
                    <div key={index} className='bg-[#353535] p-3 rounded-[20px] max-w-[300px]'>
                        <div className='w-full'>
                            <Slider {...settings}>
                                {
                                    item.project_images.map((items, i) => (
                                        <div key={i} className='rounded-[20px] max-h-[180px]'>
                                            {Fancybox.bind('[data-fancybox="gallery"]', { src: items.img })}
                                            <img className='h-full  rounded-[20px]' src={items.img} alt="" data-fancybox="gallery" />
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                        <div className='grid gap-4 mt-5'>
                            <h5 className='text-white font-medium text-2xl'>{item.title}</h5>
                            <ul className='text-white text-sm font-light'>
                                <li className='flex justify-between mb-2'>
                                    <p>Общая площадь</p>
                                    <span>{item.area}м<sup>2</sup></span>
                                </li>
                                <li className='flex justify-between'>
                                    <p>Материал</p>
                                    <span dangerouslySetInnerHTML={{ __html: item.description }} />
                                </li>
                            </ul>
                            <Button children={'Узнать подробнее'} />
                        </div>
                    </div>
                )) : 'loading...'}
        </div>
    )
}

export default ProjectsPage