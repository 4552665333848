import React, { useEffect, useState } from 'react'
import Button from '../components/button'
import Card from '../components/card'
import Projects from '../components/projects'
import Form from '../components/form'
import { useLocation } from 'react-router-dom'
import FormModal from '../components/form_modal'
import { useDispatch, useSelector } from 'react-redux'
import { getMain } from '../redux/main/main'

function HomePage() {

  const [showModal, setShowModal] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const main = useSelector(state => state.mainSlice.main)
  console.log(main);

  useEffect(() => {
    dispatch(getMain())
  }, [dispatch])

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (location.state?.scrollToStages) {
      const el = document.getElementById('stages');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);


  return (
    <>
      {main ? (
        <div className='grid gap-20'>
          {
            main.main && main.main.map((item, index) => (
              <div key={index} className='bg-custom-pattern h-[830px] lg:h-[600px] bg-cover bg-center bg-no-repeat relative'>
                <div className='bg-custom-card2 h-full'>
                  <div className='px-[30px] md:px-[20px]'>
                    <div className='flex flex-col justify-start place-items-center pt-[70px] md:pt-[50px]'>
                      <div className='flex gap-10 lg:gap-2'>
                        {['Экологичность', 'Качество', 'Надежность'].map((item, index) => (
                          <p
                            className='text-white font-ligth text-[16px] lg:text-[12px] border border-white rounded-[36px] p-[8px]  px-[33px] md:p-[4px] md:px-[10px]'
                            key={index}>{item}</p>
                        ))}
                      </div>
                      <div className='grid gap-20 place-items-center'>
                        <h1 className='text-white font-bold text-[85px] text-center leading-[80px] lg:text-[40px] lg:leading-[40px]'>
                          {item.tagline1}
                        </h1>
                        <p className='text-white text-center text-[24px] font-light lg:text-[18px]'>{item.tagline2}</p>
                        <Button children={'Хочу свой дом'}
                          onClick={handleShowModal} />
                        {showModal && <FormModal handleShowModal={handleShowModal} />}
                      </div>
                      <div className='flex gap-10 absolute bottom-[-30px] lg:grid lg:grid-cols-1 lg:bottom-[-290px] lg:gap-5'>
                        {
                          item.main_children.map((items, i) => {
                            return (
                              <div key={i} className='flex items-center gap-5 bg_color rounded-[8px] p-[4px] px-[10px]'>
                                <div className='max-w-[36px]'>
                                  <img className='w-full' src={`https://isakurulush.kg${items.icon}`} alt="" />
                                </div>
                                <div>
                                  <span className='text-[24px] text-white font-bold'>{items.digit}+</span>
                                  <p className='text-[16px] text-white font-ligth'>{items.title}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          <div className='pt-[120px] px-[30px] md:px-[20px] lg:pt-[350px]'>
            <div className='grid gap-10 lg:gap-5'>
              <Card text={'О нас'} />
              <h2 className='text-white font-medium text-[60px] leading-[60px] lg:text-[40px] lg:leading-[40px]'>{main.about && main.about[1].title}</h2>
              <div className='flex justify-center lg:justify-start'>
                <Button children={'Заказать расчет дома'} onClick={handleShowModal} />
                {showModal && <FormModal handleShowModal={handleShowModal} />}
              </div>
            </div>
            <div className='grid grid-cols-2 gap-10 items-center pt-[60px] lg:grid-cols-1 lg:gap-0 lg:pt-0'>
              <div className='bg-custom-pattern2 bg-cover bg-center flex items-center justify-center min-h-[700px] max-w-[700px]'>
                <div className='max-w-[400px]'>
                  <img className='w-full' src={`https://isakurulush.kg${main.about && main.about[1].img}`} alt="" />
                </div>
              </div>
              <div className=''>
                <p className='text-white font-ligth text-[16px] opacity-70 lg:text-[14px]' dangerouslySetInnerHTML={{ __html: main.about && main.about[1].text }} />
              </div>
            </div>
          </div>
          <div className='bg-custom-pattern3 bg-cover bg-center bg-no-repeat px-[30px] md:px-[20px]'>
            <div className='grid grid-cols-2 gap-10 items-center lg:grid-cols-1'>
              <div className='grid gap-10 lg:gap-5'>
                <h2 className='text-white font-medium text-[40px] md:text-[30px]'>{main.about && main.about[0].title}</h2>
                <p className='text-white text-[16px] font-light opacity-80 md:text-[14px]' dangerouslySetInnerHTML={{ __html: main.about && main.about[0].text }} />
              </div>
              <div>
                <div className='max-w-[500px] bg-custom-pattern4 bg-cover bg-center bg-no-repeat'>
                  <img className='w-full' src={`https://isakurulush.kg${main.about && main.about[0].img}`} alt="" />
                </div>
                <div className='bg_color text-white text-center font-medium text-[20px] rounded-[8px] py-[10px] px-[15px] md:text-[16px]'>
                  Надежность и экологичность в каждой постройке
                </div>
              </div>
            </div>
            {
              main.advantages && main.advantages.map((item, index) => (
                <div key={index} className='pt-[30px]'>
                  <Card text={'Преимущества'} />
                  <h2 className='text-white font-medium text-[40px] pt-[30px] lg:pt-[15px] md:text-[28px]'>{item.title}</h2>
                  <div className='grid grid-cols-3 pt-[30px] sm:grid-cols-1'>
                    {item.advantage_children.map((item, index) => (
                      <div key={index} className='flex items-center border border-[rgba(255,255,255,0.5)] p-5'>
                        <p className='text-white text-[16px] font-light opacity-80 lg:text-[14px]' dangerouslySetInnerHTML={{ __html: item.text }} />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            }
          </div>
          <div className='bg-custom-pattern5 bg-cover bg-center bg-no-repeat h-[600px]'>
            {
              main.our_approach && main.our_approach.map((item, index) => (
                <div key={index} className='bg-custom-card2 h-full flex flex-col items-center justify-center gap-10 px-[30px] md:px-[20px]'>
                  <h5 className='text-white font-medium text-[22px]'>Наш подход</h5>
                  <div className='flex gap-10 md:gap-5'>
                    {item.approach_children.map((item, index) => (
                      <Card key={index} text={item.title} />
                    ))}
                  </div>
                  <h2 className='text-white text-center font-medium text-[40px] md:text-[28px]'>{item.title}</h2>
                </div>
              ))
            }
          </div>
          <Projects handleShowModal={handleShowModal} showModal={showModal} />
          {
            main.stages && main.stages.map((item, index) => (
              <div key={index} className='pt-[30px] px-[30px] md:px-[20px] relative pb-[30px]' id='stages'>
                <Card text={'Этапы'} />
                <div>
                  <h2 className='text-white font-medium text-[40px] pt-[30px] pb-[20px] md:text-[28px]'>{item.title}</h2>
                  <div className='flex'>
                    <Button children={'Начать работу'} onClick={handleShowModal} />
                    {showModal && <FormModal handleShowModal={handleShowModal} />}
                  </div>
                  <h4 className='text-white text-[15px] font-medium opacity-80 pt-[20px] md:text-[14px]'>{item.description}</h4>
                </div>
                <div className='max-w-[800px] pt-[30px] grid gap-10'>
                  {
                    item.stages_children.map((item, index) => (
                      <div key={index} className='w-full'>
                        <hr />
                        <div className='flex items-center gap-5'>
                          <div className='flex'><Button children={item.stage_number} /></div>
                          <div>
                            <h5 className='text-white font-medium text-[18px] mt-10 md:text-[16px]'>{item.title}</h5>
                            <p className='text-white text-[14px] font-light opacity-80 pt-[10px] md:text-[12px]'>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <div className='bg-custom-pattern6 bg-cover bg-center bg-no-repeat absolute bottom-0 right-0 w-full h-full z-[-1]'></div>
                </div>
              </div>
            ))
          }
          {
            main.process && main.process.map((item, index) => (
              <div key={index} className='bg-custom-pattern7 bg-cover bg-center bg-no-repeat'>
                <div className='px-[30px] md:px-[20px]'>
                  <h2 className='text-white font-medium text-[35px] pt-[30px] md:text-[28px]'>{item.title}</h2>
                  <div className='grid grid-cols-2 gap-10 items-center mt-10 sm:grid-cols-1'>
                    <div key={index} className='grid gap-5'>
                      {item.process_children.map((item, index) => (
                        <div key={index} className='flex gap-5 items-center'>
                          <img src={`https://isakurulush.kg${item.icon}`} alt="" />
                          <p className='text-white text-[18px] font-light opacity-80 md:text-[16px]'>{item.text}</p>
                        </div>
                      ))}
                    </div>
                    <div className='w-full'>
                      <img src={`https://isakurulush.kg${item.banner}`} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {
            main.why_us && main.why_us.map((item, index) => (
              <div key={index} className='pt-[20px] px-[30px] md:px-[20px]'>
                <Card text={'Почему мы'} />
                <h2 className='text-white font-medium text-[40px] pt-[20px] md:text-[28px]'>{item.title}</h2>
                <div className='flex mt-5 mb-5'>
                  <Button children={'Хочу с вами работать'} onClick={handleShowModal} />
                  {showModal && <FormModal handleShowModal={handleShowModal} />}
                </div>
                <div className='grid grid-cols-2 gap-10 px-[100px] lg:px-0 md:grid-cols-1 md:gap-5'>
                  {
                    item.why_us_children.map((items, i) => (
                      <div key={i}>
                        <h5 className='text-white font-medium text-[18px] md:text-[16px]'>{items.title}</h5>
                        <p className='text-white text-[14px] font-light opacity-80 pt-[10px] md:text-[12px]'>{items.description}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <Form
            title={'Получите индивидуальное предложение на строительство дома под ключ'}
            text={'Оставьте свои контактные данные и мы с вами свяжемся'}
          />
        </div>
      ) : 'loading...'}
    </>
  )
}

export default HomePage