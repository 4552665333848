import React from 'react'

export default function Button({ children, onClick, disabled }) {
    return (
        <button
            className='flex items-center justify-center bg-[#394e25] rounded-[60px] p-3 text-white text-[14px] font-bold cursor-pointer transition hover:opacity-80 border-none whitespace-nowrap'
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}
