import { createSlice } from '@reduxjs/toolkit'
import { Api } from '../../api'

const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        gallery: []
    },
    reducers: {
        SetGallery: (state, action) => {
            state.gallery = action.payload
        }
    }
})

export const { SetGallery } = gallerySlice.actions
export default gallerySlice.reducer

export const getGallery = () => {
    return async (dispatch) => {
        try {
            const response = await Api.get('gallery/')
            dispatch(SetGallery(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}