import React, { useEffect, useState } from 'react'
import Card from '../components/card'
import Button from '../components/button'
import { useDispatch, useSelector } from 'react-redux'
import { getMain } from '../redux/main/main'
import FormModal from '../components/form_modal'
import building from '../img/about-page.jpg'

function AboutPage() {

    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    const main = useSelector(state => state.mainSlice.main)
    console.log(main)

    useEffect(() => {
        dispatch(getMain())
    }, [dispatch])

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    return (
        <div className='pt-[80px] px-[30px] md:px-[20px]'>
            <div className='grid gap-10 lg:gap-5'>
                <Card text={'О нас'} />
                <h2 className='text-white font-medium text-[60px] leading-[60px] lg:text-[40px] lg:leading-[40px]'>Строим монолитные каркасы для многоэтажных домов и гостиниц</h2>
                <div className='flex justify-center lg:justify-start'>
                    <Button children={'Заказать расчет дома'} onClick={handleShowModal} />
                    {showModal && <FormModal handleShowModal={handleShowModal} />}
                </div>
            </div>
            <div className='grid gap-10 items-center pt-[40px] lg:grid-cols-1'>
                <div className=''>
                    <p className='text-white font-ligth text-[16px] opacity-70 lg:text-[14px]'>
                        Строительная компания "ИСА" уже более одного года успешно работает на рынке строительства монолитных каркасов для многоэтажных домов, гостиниц и других объектов. За это время мы завоевали доверие наших клиентов благодаря высокому качеству выполнения работ и профессионализму нашей команды. Мы стремимся к тому, чтобы каждый проект был завершен в срок и с минимальными затратами для заказчика, сохраняя при этом высокий уровень исполнения.
                        <br />
                        <br />
                        Мы гордимся тем, что в нашем арсенале имеются все необходимые документы и лицензии, подтверждающие нашу компетентность и профессионализм в области строительства. Это позволяет нам не только соответствовать всем строительным нормам и стандартам, но и предоставлять гарантии на выполненные работы. Наша команда состоит из опытных инженеров, архитекторов и строителей, которые непрерывно совершенствуют свои навыки и знания, следят за новейшими тенденциями и технологиями в строительной отрасли.
                    </p>
                </div>
                <div className='max-w-[1200px]'>
                    <img className='w-full' src={building} alt="" />
                </div>
                <div className='text-white grid gap-5'>
                    <h5 className='font-medium text-[24px] lg:text-[20px]'>Наши Преимущества</h5>
                    <ol className='list-decimal list-inside grid gap-5 font-ligth text-[16px] opacity-70 lg:text-[14px]'>
                        <li className=''>Опыт и профессионализм <br />
                            Мы гордимся нашей командой высококвалифицированных специалистов с многолетним опытом в строительстве. Каждый наш проект выполняется с учетом новейших технологий и строительных стандартов. Мы обеспечиваем профессиональное сопровождение на всех этапах строительства, что гарантирует отличные результаты и удовлетворение наших клиентов.
                        </li>
                        <li>Экологическая безопасность <br />
                            Мы заботимся о будущем нашей планеты, поэтому используем экологически чистые материалы и технологии, минимизирующие воздействие на окружающую среду. Наша компания стремится к устойчивому развитию и снижению углеродного следа, что делает наши проекты не только качественными, но и безопасными для природы.
                        </li>
                        <li> Качество и надежность <br />
                            Мы гарантируем высокое качество и надежность всех выполненных работ. Используем только проверенные материалы и современные методы строительства, что обеспечивает долговечность наших объектов. Контроль качества на каждом этапе позволяет нам соответствовать самым высоким стандартам и ожиданиям наших клиентов.
                        </li>
                        <li>Комплексный подход <br />
                            Наш подход к строительству включает в себя полный спектр услуг – от проектирования и согласования до сдачи объекта в эксплуатацию. Мы предлагаем индивидуальные решения, учитывающие все пожелания клиента. Наша цель – сделать процесс строительства максимально удобным и прозрачным для заказчика, обеспечивая успешное завершение каждого проекта.
                        </li>
                    </ol>
                    <h5 className='font-medium text-[24px] lg:text-[20px]'>Как будет организован рабочий процесс</h5>
                    <ol className='list-decimal list-inside grid gap-5 font-ligth text-[16px] opacity-70 lg:text-[14px]'>
                        <li> Консультация и планирование. <br />
                            Мы начинаем с подробного обсуждения вашего проекта. На этом этапе выявляются все требования и пожелания клиента, что позволяет нам создать точный план и смету работ.
                        </li>
                        <li>Проектирование и согласование. <br />
                            Наши архитекторы и инженеры разрабатывают проект, который учитывает все технические и эстетические аспекты. Проект проходит все необходимые согласования и утверждения.
                        </li>
                        <li>Подготовка площадки. <br />
                            Перед началом строительства мы проводим все подготовительные работы: расчистка участка, проведение необходимых коммуникаций и подготовка фундамента.
                        </li>
                        <li>Строительство. <br />
                            Наша команда профессионалов приступает к возведению монолитного каркаса. Все работы выполняются строго по графику с использованием качественных материалов и современных технологий.
                        </li>
                        <li>Контроль качества. <br />
                            На каждом этапе строительства проводится тщательный контроль качества, что гарантирует соответствие всех работ установленным стандартам и требованиям.
                        </li>
                        <li>Сдача объекта. <br />
                            После завершения всех работ мы проводим финальную проверку и сдаем объект клиенту. На этом этапе осуществляется передача всей необходимой документации и гарантийных обязательств.
                        </li>
                        <li>Поддержка и обслуживание. <br />
                            Мы остаемся на связи с нашими клиентами и после завершения строительства, обеспечивая необходимую поддержку и обслуживание объектов.
                        </li>
                    </ol>
                    <h5 className='font-medium text-[24px] lg:text-[20px]'>Гарантируем качество и долговечность</h5>
                    <p className='font-ligth text-[16px] opacity-70 lg:text-[14px]'>Наша компания "ИСА" гарантирует качество и долговечность всех выполненных работ. Мы используем только проверенные материалы и современные технологии, что обеспечивает надежность и безопасность каждого объекта. Высокий уровень профессионализма нашей команды позволяет нам создавать строения, которые служат долгие годы, радуя своих владельцев комфортом и эстетикой. Доверяя нам, вы выбираете качество и уверенность в будущем.</p>
                    <h5 className='font-medium text-[24px] lg:text-[20px]'>Контактная информация</h5>
                    <p className='font-ligth text-[16px] opacity-70 lg:text-[14px'>Если у вас есть вопросы или вы хотите обсудить ваш проект, свяжитесь с нами. Мы всегда готовы предоставить подробную информацию и консультации по всем интересующим вас вопросам. Наша цель – сделать процесс сотрудничества максимально удобным и результативным для вас.
                        <br />
                        Компания "ИСА" – ваш надежный партнер в мире строительства. Мы гарантируем, что сотрудничество с нами будет выгодным, комфортным и результативным. Свяжитесь с нами сегодня, чтобы обсудить ваш проект и узнать больше о том, как мы можем помочь вам реализовать ваши строительные идеи.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutPage