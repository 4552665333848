import React, { useState } from 'react'
import Button from '../button'
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { NavLink, useNavigate } from 'react-router-dom';
import FormModal from '../form_modal';
import logo from '../../img/logo-isakurulush.jpg'

function Header() {

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const handleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    const handleScroll = (navigate) => {
        navigate('/', { state: { scrollToStages: true } });
    };

    return (
        <header className='bg-[#1b1b1b] w-full max-w-[1920px]  mx-auto'>
            <div className='text-white flex justify-between p-[15px] px-[30px] md:px-20 items-center w-full'>
                <div className='max-w-[60px]'>
                    <NavLink to={'/'}>
                        <img className='w-full' src={logo} alt="ИСА СТРОЙ" />
                    </NavLink>
                </div>
                <nav className='lg:hidden'>
                    <ul className='flex gap-10 text-sm font-light xl:gap-15'>
                        <li className=' hover:opacity-80'><NavLink to={'/about'}>О нас</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/projects'}>Мы строим</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/'} onClick={() => handleScroll(navigate)}>Этапы сотрудничества</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/gallery'}>Галерея</NavLink></li>
                        <li className=' hover:opacity-80'><NavLink to={'/contacts'}>Контакты</NavLink></li>
                    </ul>
                </nav>
                <div className='flex gap-10 items-center text-sm font-light xl:gap-15'>
                    <NavLink className='lg:hidden hover:opacity-80' to={'tel:+996 (503) 22-37-11'}>+996 (503) 22-37-11</NavLink>
                    <div className='lg:hidden'>
                        <Button children={"Связаться с нами"} onClick={handleShowModal} />
                        {showModal && <FormModal handleShowModal={handleShowModal} />}
                    </div>
                    <FiMenu className='lgmin:hidden cursor-pointer text-3xl' onClick={handleShowMenu} />
                </div>
                {showMenu && (
                    <>
                        <div className='bg-custom-green fixed top-0 left-0 w-full h-full z-10' onClick={handleShowMenu}></div>
                        <div className='fixed top-0 left-0 h-full w-[60%] bg-black p-5 z-10 animate-anim'>
                            <IoMdClose className='lgmin:hidden cursor-pointer text-4xl mb-5' onClick={handleShowMenu} />
                            <div className='grid gap-10'>
                                <nav>
                                    <ul className='grid gap-10 text-sm font-light xl:gap-15'>
                                        <li onClick={handleShowMenu} className=' hover:opacity-80'><NavLink to={'/about'}>О нас</NavLink></li>
                                        <li onClick={handleShowMenu} className=' hover:opacity-80'><NavLink to={'/projects'}>Мы строим</NavLink></li>
                                        <li onClick={handleShowMenu} className=' hover:opacity-80'><NavLink to={'/'} onClick={() => handleScroll(navigate)}>Этапы сотрудничества</NavLink></li>
                                        <li onClick={handleShowMenu} className=' hover:opacity-80'><NavLink to={'/gallery'}>Галерея</NavLink></li>
                                        <li onClick={handleShowMenu} className=' hover:opacity-80'><NavLink to={'/contacts'}>Контакты</NavLink></li>
                                    </ul>
                                </nav>
                                <div className='grid gap-10 items-center text-sm font-light xl:gap-15'>
                                    <NavLink onClick={handleShowMenu} to={'tel:+996 (503) 22-37-11'}>+996 (503) 22-37-11</NavLink>
                                    <div>
                                        <Button children={"Связаться с нами"} onClick={handleShowModal} />
                                        {showModal && <FormModal handleShowModal={handleShowModal} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </header>
    )
}

export default Header