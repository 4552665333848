import React, { useState } from 'react'
import Button from './button'
import { IoMdClose } from "react-icons/io";
import { Api } from '../api';

function FormModal({ handleShowModal }) {

    const [inputChange, setInputChange] = useState({
        name: '',
        phone: '',
        email: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputChange({ ...inputChange, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInputChange({
            name: '',
            phone: '',
            email: ''
        })
        const newFormData = {
            full_name: inputChange.name,
            phone: inputChange.phone,
            email: inputChange.email,
        }

        try {
            await Api.post('request/', newFormData)
            console.log('success');
            alert('Спасибо за заявку! Мы свяжемся с Вами в ближайшее время.')
        } catch (error) {
            console.error('Ошибка отправки данных на сервер:', error);
        }
        console.log(newFormData);
    }

    return (
        <>
            <div className='bg-custom-green fixed top-0 left-0 w-full h-full z-10' onClick={handleShowModal}></div>
            <div className='bg-white p-5 grid gap-4 fixed top-[20%] left-[20%] right-[20%] z-10 md:left-0 md:right-0'>
                <div className='ml-auto'>
                    <IoMdClose className='text-black text-2xl cursor-pointer' onClick={handleShowModal} />
                </div>
                <h3 className='text-center text-black text-[22px] lg:text-[18px]'>
                    Укажите Ваш актуальный телефон и мы свяжемся с Вами в ближайшее время
                </h3>
                <form action="" className='grid items-center justify-center gap-10 lg:flex-col lg:gap-5'>
                    <input type="text" placeholder='Ваше имя *'
                        className='border outline-none rounded-[5px] p-3 text-[15px] w-full'
                        name='name'
                        value={inputChange.name}
                        onChange={handleChange}
                    />
                    <input type="text" placeholder='Ваш телефон *'
                        className='border outline-none rounded-[5px] p-3 text-[15px] w-full'
                        name='phone'
                        value={inputChange.phone}
                        onChange={handleChange}
                    />
                    <input type="e-mail" placeholder='Ваш e-mail *'
                        className='border outline-none rounded-[5px] p-3 text-[15px]  w-full'
                        name='email'
                        value={inputChange.email}
                        onChange={handleChange}
                    />
                </form>
                <div className='flex justify-center'>
                    <Button children={'Отправить заявку'} onClick={handleSubmit}
                        disabled={inputChange.name && inputChange.phone && inputChange.email ? false : true} />
                </div>
            </div>
        </>
    )
}

export default FormModal