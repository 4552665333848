import React, { useState } from 'react'
import Button from './button'
import { Api } from '../api'

function Form({ title, text}) {

    const [inputChange, setInputChange] = useState({
        name: '',
        phone: '',
        email: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputChange({ ...inputChange, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInputChange({
            name: '',
            phone: '',
            email: ''
        })
        const newFormData = {
            full_name: inputChange.name,
            phone: inputChange.phone,
            email: inputChange.email,
        }

        try {
            await Api.post('request/', newFormData)
            console.log('success');
            alert('Спасибо за заявку! Мы свяжемся с Вами в ближайшее время.')
        } catch (error) {
            console.error('Ошибка отправки данных на сервер:', error);
        }
        console.log(newFormData);
    }

    return (
        <div className='px-[30px] md:px-[20px] py-[30px]'>
            <div className='bg_color rounded-[20px] p-[70px] grid gap-5 sm:p-5'>
                <h2 className='text-white font-medium text-center text-[40px]  lg:text-[20px]'>{title}</h2>
                <p className='text-white text-[14px] text-center font-light opacity-80 md:text-[12px]'>{text}</p>
                <form action="" className='flex items-center justify-center gap-10 lg:flex-col lg:gap-5'>
                    <input type="text" placeholder='Ваше имя *'
                        className='outline-none rounded-[50px] p-3 text-[15px] w-full'
                        name='name'
                        value={inputChange.name}
                        onChange={handleChange}
                    />
                    <input type="text" placeholder='Ваш телефон *'
                        className='outline-none rounded-[50px] p-3 text-[15px] w-full'
                        name='phone'
                        value={inputChange.phone}
                        onChange={handleChange}
                    />
                    <input type="e-mail" placeholder='Ваш e-mail *'
                        className='outline-none rounded-[50px] p-3 text-[15px]  w-full'
                        name='email'
                        value={inputChange.email}
                        onChange={handleChange}
                    />
                </form>
                <div className='flex justify-center'>
                    <Button children={'Получить проект'} onClick={handleSubmit}
                        disabled={inputChange.name && inputChange.phone && inputChange.email ? false : true} />
                </div>
            </div>
        </div>
    )
}

export default Form