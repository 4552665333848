import React from 'react'

function Card({text}) {
    return (
        <div className='flex items-center'>
            <p
                className='text-white font-ligth text-[16px] lg:text-[12px] border border-white rounded-[36px] p-[8px]  px-[33px] md:p-[4px] md:px-[10px]'>
                    {text}
            </p>
        </div>
    )
}

export default Card